<template>
  <div>
    <div class="d-flex justify-content-center">
      <ClipLoader
        :loading="this.loading"
        class="custom-class"
        color="#18328d"
        :size="150"
        sizeUnit="px"
      />
    </div>
    <div class="card card-custom gutter-b">
      <div class="col-md-12">
        <b-form>
          <div class="card-header d-flex justify-content-between">
            <h5 class="title-card-custom">Detail Peraturan</h5>
            <div class="mb-3 float-right">
              <a v-b-toggle href="#example-collapse" @click.prevent
                ><i
                  class="fa fa-chevron-down when-opened"
                  style="width: 16px; height: 8px; color: #000000"
                ></i
              ></a>
              <a v-b-toggle href="#example-collapse" @click.prevent
                ><i
                  class="fa fa-chevron-right when-closed"
                  style="width: 16px; height: 8px; color: #000000"
                ></i
              ></a>
            </div>
          </div>
          <div class="card-body">
            <b-collapse id="example-collapse" visible>
              <div class="col">
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-black-75"
                    >Jenis Produk Hukum</label
                  >
                  <div class="col-lg-8 col-xl-8">
                    <label
                      class="col-xl-8 col-lg-8 col-form-label text-black-75"
                      >{{ this.dokumentasi.jenis_perundangan ? this.dokumentasi.jenis_perundangan.name : '' }}</label
                    >
                  </div>
                </div>

                <!--                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-black-75"
                    >Nama Produk Hukum</label
                  >
                  <div class="col-lg-8 col-xl-8">
                    <label
                      class="col-xl-8 col-lg-8 col-form-label text-black-75"
                      >{{ this.dokumentasi.nama }}</label
                    >
                  </div>
                </div>-->

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-black-75"
                    >Judul</label
                  >
                  <div class="col-lg-8 col-xl-8">
                    <label
                      class="col-xl-8 col-lg-8 col-form-label text-black-75"
                      >{{ this.dokumentasi.judul }}</label
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    class="col-xl-3 col-lg-3 col-md-6 col-form-label text-black-75"
                    >Tahun</label
                  >
                  <div class="col-lg-8 col-xl-8 col-md-6">
                    <label
                      class="col-xl-8 col-lg-8 col-md-6 col-form-label text-black-75"
                      >{{ this.dokumentasi.tahun }}</label
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-black-75"
                    >Sinopsis</label
                  >
                  <div class="col-lg-8 col-xl-8">
                    <label
                      class="col-xl-8 col-lg-8 col-form-label text-black-75"
                      >{{ this.dokumentasi.sinopsis }}</label
                    >
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </b-form>
      </div>
    </div>
    <div class="card card-custom gutter-b">
      <div class="col-md-12">
        <b-form>
          <div class="card-header d-flex justify-content-between">
            <h5 class="title-card-custom">Unduh Berkas</h5>
            <div class="mb-3 float-right">
              <a v-b-toggle href="#example-collapse2" @click.prevent
                ><i
                  class="fa fa-chevron-down when-opened"
                  style="width: 16px; height: 8px; color: #000000"
                ></i
              ></a>
              <a v-b-toggle href="#example-collapse2" @click.prevent
                ><i
                  class="fa fa-chevron-right when-closed"
                  style="width: 16px; height: 8px; color: #000000"
                ></i
              ></a>
            </div>
          </div>
          <div class="card-body">
            <b-collapse id="example-collapse2" visible>
              <div class="col">
                <div class="form-group row">
                  <div class="col-lg-12 col-xl-12">
                    <div>
                      <ul>
                        <li 
                          v-for="(berkas, index) in this.dokumentasi.produk_path ? this.dokumentasi.produk_path.split(';') : []" 
                          :key="index"
                          >
                          <a :href="downloadPath+'/'+berkas" @click="doDownload" target="_blank">
                            {{ berkas.split('/').pop().substring(8) }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </b-form>
      </div>
    </div>
    <div class="card card-custom gutter-b">
      <div class="col-md-12">
        <b-form>
          <div class="card-header d-flex justify-content-between">
            <h4 class="title-card-custom">Status</h4>
            <div class="mb-3 float-right">
              <a v-b-toggle href="#example-collapse3" @click.prevent
                ><i
                  class="fa fa-chevron-down when-opened"
                  style="width: 16px; height: 8px; color: #000000"
                ></i
              ></a>
              <a v-b-toggle href="#example-collapse3" @click.prevent
                ><i
                  class="fa fa-chevron-right when-closed"
                  style="width: 16px; height: 8px; color: #000000"
                ></i
              ></a>
            </div>
          </div>
          <div class="card-body">
            <b-collapse id="example-collapse3" visible>
              <div class="d-flex">
                <div class="flex-grow-1">
                  <!--begin: Content-->
                  <div class="align-items-center justify-content-between">
                    <div v-html="this.progress_mengubah" class="mb-10"></div>
                    <div v-html="this.progress_mencabut" class="mb-10"></div>
                    <div v-html="this.progress_ujimk"></div>
                  </div>
                  <!--end: Content-->
                </div>
                <!--end: Info-->
              </div>
            </b-collapse>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { handleErrors } from "@/core/appUtil/util";
import { ClipLoader } from "@saeris/vue-spinners";

export default {
  name: "DetailDokumentasiList",
  components: {
    ClipLoader
  },
  data() {
    return {
      dokumentasi: [],
      progress: [],
      paths: null,
      path_dokumentasi: "",
      loading: true,
      progress_mengubah: null,
      progress_mencabut: null,
      progress_ujimk: null,
      downloadPath: process.env.VUE_APP_FILE_DOWNLOAD_PATH,
    };
  },
  mounted() {
    this.getDokumentasi();
    this.getDokumentasiProgres();

    this.id_dokumen = this.$route.params.id;
  },
  methods: {
    getDokumentasi(attempt = 0) {
      attempt++;

      let urlPath = `dokumentasi`;

      // Store id dokumentasi to inform system that this page already visited
      let currentAccessedDocument = localStorage.getItem("accessedDocuments");
      if (currentAccessedDocument) {
        currentAccessedDocument = JSON.parse(currentAccessedDocument);

        if (currentAccessedDocument.includes(this.$route.params.id)) {
          urlPath = `dokumentasi/increase-view`;
        } else {
          currentAccessedDocument.push(this.$route.params.id);
          localStorage.setItem("accessedDocuments", JSON.stringify(currentAccessedDocument));
        }
      } else {
        localStorage.setItem("accessedDocuments", JSON.stringify([this.$route.params.id]));
        urlPath = `dokumentasi/increase-view`;
      }

      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL + `/${urlPath}/${this.$route.params.id}`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => response.json())
      .then(items => {
        if (items.status === "error" && attempt <= 3) {
          this.getDokumentasi(attempt);

        } else {
          this.dokumentasi = items.data;
          this.paths = this.dokumentasi.produk_path;
          this.loading = false;
          let path = this.paths.split(";");

          let output = "";
          if (path[0].length > 0) {
            output = `<ul>`;
            path.forEach(path => {
              let fnames = path.split("/");
              let namaFile = fnames[1].substring(8);

              // START Check if new format file name
              let uniqueCode = fnames[1].split("-").pop();
              if (uniqueCode.length > 0) {
                uniqueCode = uniqueCode.split(".")[0];
              }

              let isUniqueCode = uniqueCode ? uniqueCode.length === 7 : false;
              if (isUniqueCode) {
                // Get string before extension
                namaFile = fnames[1].replace(`-${uniqueCode}`, "");
              }
              // END Check if new format file name

              output += `<li><a href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${path}">${namaFile}</a></li>`;
            });
            output += "</ul>";
          } else {
            output = "Tidak Ada Dokumen";
          }
          this.path_dokumentasi = output;
          return output;
        }
      });
    },
    getDokumentasiProgres(attempt = 0) {
      attempt++;

      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL +
            `/dokumentasi-progress?dokumentasi_id=${this.$route.params.id}`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          if (items.status === "error" && attempt <= 3) {
            this.getDokumentasiProgres(attempt);

          } else {
            this.progress = items.data;

            let filteredMengubah = this.progress.filter(
              member => member.status === "Mengubah"
            );

            let progres = "";
            if (filteredMengubah.length > 0) {
              progres = `<h6 class="title-status">Mengubah: </h6><div>`;
              filteredMengubah.forEach(item => {
                let fnames = item.path.split("/");
                let namaFile = fnames[1].substring(8);
                progres += `<ul
                            class="ml-3"
                            style="font-size:14px; font-weight: normal"
                          >
                            <li class="ml-5">
                              <a href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${item.path}" target="_blank">${namaFile}</a> tentang ${item.tentang}
                            </li>
                          </ul>`;
              });
              progres += "</div>";
            } else {
              progres = "Tidak Ada Status";
            }
            this.progress_mengubah = progres;

            let filteredMencabut = this.progress.filter(
              member => member.status === "Mencabut"
            );

            let progres_cabut = "";
            if (filteredMencabut.length > 0) {
              progres_cabut = `<h6 class="title-status">Mencabut: </h6><div>`;
              filteredMencabut.forEach(item => {
                let fnames = item.path.split("/");
                let namaFile = fnames[1].substring(8);
                progres_cabut += `<ul
                            class="ml-3"
                            style="font-size:14px; font-weight: normal"
                          >
                            <li class="ml-5">
                              <a href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${item.path}" target="_blank">${namaFile}</a> tentang ${item.tentang}
                            </li>
                          </ul>`;
              });
              progres_cabut += "</div>";
            }
            this.progress_mencabut = progres_cabut;

            let filteredUjiMk = this.progress.filter(
              member => member.status === "Uji Materi MK"
            );

            let progres_mk = "";
            if (filteredUjiMk.length > 0) {
              progres_mk = `<h6 class="title-status">Uji Materi MK: </h6><div>`;
              filteredUjiMk.forEach(item => {
                let fnames = item.path.split("/");
                let namaFile = fnames[1].substring(8);
                progres_mk += `<ul
                            class="ml-3"
                            style="font-size:14px; font-weight: normal"
                          >
                            <li class="ml-5">
                              <a href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${item.path}" target="_blank">${namaFile}</a> tentang ${item.tentang}
                            </li>
                          </ul>`;
              });
              progres_mk += "</div>";
            }
            this.progress_ujimk = progres_mk;
          }
        });
    },
    doDownload(attempt = 0) {
      attempt++;

      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL + `/dokumentasi/increase-download/${this.$route.params.id}`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => response.json())
      .then(items => {
        if (items.status === "error" && attempt <= 3) {
          this.doDownload();
        }
      });
    }
  }
};
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
